<template>
  <!-- begin::kb-main -->
  <main class="kb-main main-center">
    <div :class="isMobile ? 'main-content' : 'min-component'">
      <div class="error-page mt-6 mb-6">
        <div class="images">
          <img src="@/assets/lxp/images/common/img_empty.png" alt=""/>
        </div>
        <h3 class="title">접속종료</h3>
        <p class="description">
          장시간 사용하지않아 접속이 종료되었습니다.<br/> 다시 로그인 해 주시기 바랍니다
        </p>
        <div class="buttons">
          <a href="javascript:" class="kb-btn kb-btn-primary" :class="isMobile ? 'kb-btn-content-item' : 'kb-btn-xl'" @click="goToSignin"><span class="text">다시 로그인하기</span></a>
        </div>
      </div>
    </div>
  </main>
  <!-- end::kb-main -->
</template>

<script>
import navigationUtils from "@/assets/js/navigationUtils";
import {useRouter} from "vue-router";
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "SessionExpired",
  setup() {

    const store = useStore();
    const router = useRouter();

    const returnQuery = computed(() => store.state.common.returnQuery);


    const goToSignin = () => {
      router.push({name: 'Signin', query: returnQuery.value});
    }


    return {
      isMobile: navigationUtils.any(),
      goToSignin
    }
  }
}
</script>
